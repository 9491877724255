import { HStack, Stack, StackProps, Text, TextProps } from "@chakra-ui/react"
import { ReactNode } from "react"

const PageTitle = ({
  title,
  descriptionGroup,
  extraActions,
  stackProps,
  ...props
}: {
  title: string
  descriptionGroup?: string
  extraActions?: ReactNode
  stackProps?: StackProps
} & TextProps) => {
  return (
    <Stack
      flexDirection={{
        base: "column",
        sm: "column",
        md: "row",
        lg: "row",
      }}
      justifyContent="space-between"
      paddingBottom={2}
      borderBottom="1px solid black"
      {...stackProps}
    >
      <HStack>
        <Text
          fontFamily="GTMaru"
          fontWeight="bold"
          fontSize="xl"
          wordBreak="break-word"
          {...props}
        >
          {title}
        </Text>
        {descriptionGroup}
      </HStack>
      {extraActions}
    </Stack>
  )
}

export default PageTitle
